import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import Modal from "react-modal";
import { IoIosSearch } from "react-icons/io";
import {
  handleActionForError,
  setModalData,
} from "../../../redux/actions/errorActions";

import {ChangePosition} from './ModalWindows/change-position'

import ModalError from "../../modal-window/modal";
import "react-calendar/dist/Calendar.css";
import "./Tabs/tabs.css";
import "./laundry.css";

const MY_TOKEN = process.env.REACT_APP_USER_TOKEN;
const MY_API = process.env.REACT_APP_USER_API;

const Position = () => {
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [laundryValues, setLaundryValues] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fetchDataCalled, setFetchDataCalled] = useState(false);

  const errorModal = useSelector((state) => state.error.modalData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
 
  const fetchData = async () => {
    try {
      const response = await fetch(`${MY_API}/get_all_user_position`, {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${MY_TOKEN}`,
        },
      });

      const result = await response.json();

      if (result.errcode === 0) {
        setLaundryValues(result.result);
      } else {
        const modalData = handleActionForError(
          result.errcode,
          navigate,
          dispatch
        );
        dispatch(setModalData(modalData));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!fetchDataCalled) {
      setFetchDataCalled(true);
      setLoading(true);
      fetchData();
    }
  }, [fetchDataCalled]);

  const handleRequestPosition = async () => {
    try {
      const response = await fetch(
        `${MY_API}/get_id_user_position?user_id=${userId}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${MY_TOKEN}`,
          },
        }
      );

      const result = await response.json();
      if (result.errcode === 0) {
        setLaundryValues(result.result);
      } else {
        const modalData = handleActionForError(
          result.errcode,
          navigate,
          dispatch
        );
        dispatch(setModalData(modalData));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="filters-position">
        <div className="position-stile-modal">
          <button type="butDelete" className="butDelete" onClick={openModal}>
            Изменить позицию
          </button>
        </div>
        <Modal
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, .7)",
            },
          }}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className="admin-modal"
        >
          <ChangePosition closeModal={closeModal} />
        </Modal>
      </div>
      <div className="journal-table-position">
        <div className="position-stile-input">
          <input
            type="text"
            className="stile-input"
            placeholder="ID user"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleRequestPosition();
              }
            }}
          />
          <button
            type="button"
            className="calend search"
            onClick={handleRequestPosition}
          >
            <IoIosSearch size={"20px"} />
          </button>
        </div>
        <div
          style={{
            overflowY: "auto",
            maxHeight: "670px",
            position: "relative",
            margintop: "20px",
          }}
        >
          <table className="journal-table">
            <thead className="journal-thread">
              <tr>
                <th className="th-line th-padding-index">№</th>
                <th className="th-line th-padding-auto">ID пользователя</th>

                <th className="th-padding-auto">Позиция</th>
              </tr>
            </thead>

            <tbody className="journal-tbody">
              {loading ? (
                <tr >
                  <td colSpan={7}>
                    <div
                      className="loading"
                      style={{
                        height: "650px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <ReactLoading
                        type={"spin"}
                        color={"#5A7EF2"}
                        height={50}
                        width={50}
                      />
                    </div>
                  </td>
                </tr>
              ) : (
                laundryValues.map((item, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 1 ? "row-hover even-row" : "row-hover noeven-row"}
                  >
                    <td className="th-line th-padding-index">{index + 1}</td>
                    <td className="th-line th-padding-auto">{item.user_id}</td>
                    <td className="th-padding-auto">{item.position}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <ModalError
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, .9)",
          },
        }}
        isOpen={errorModal.isOpen}
        onClose={errorModal.closeModal}
        title={errorModal.title}
        content={errorModal.content}
      />
    </>
  );
};
export default Position;
import { LOGIN_SUCCESS, LOGIN_FAILURE  } from '../actions/authActions';

const initialState = {
  roles: '',
  loggedIn: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        roles: action.payload.roles,
        loggedIn: true
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        roles: '',
        loggedIn: false
      };
      default:
        return state;
    }
  };
  
  export default authReducer;

const initialState = {
  errorCode: null,
  errorData: null,
  modalData: {
    isOpen: false,
    title: "",
    content: "",
  },
};

const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ERROR":
      return {
        errorCode: action.payload.errorCode,
        errorData: action.payload.errorData,
        modalData: { ...state.modalData }, 
      };
    case "SET_MODAL_DATA":
      return {
        ...state,
        modalData: {
          isOpen: true,
          title: action.payload.title,
          content: action.payload.content,
        },
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        modalData: {
          isOpen: false,
          title: "",
          content: "",
        },
      };
    case "CLEAR_ERROR":
      return initialState;
    default:
      return state;
  }
};

export default errorReducer;

import React, { useState , useRef, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CiUser, CiMail } from "react-icons/ci";
import { handleActionForError } from "../../redux/actions/errorActions";
import "./login.css";
import { RxExit } from "react-icons/rx";
const MY_TOKEN = process.env.REACT_APP_USER_TOKEN;
const MY_API = process.env.REACT_APP_USER_API;

const EmailSend = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [msg, setMsg] = useState(null);

  useEffect(() => {
    setError("");
  }, [user, email]);

  const handleActionForErrorOnce = (errorCode) => {
    if (!error) {
      const errorMessage = handleActionForError(errorCode);
      setError(errorMessage.content);
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${MY_API}/reset_password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${MY_TOKEN}`,
        },
        body: JSON.stringify({
          login: user,
          email: email,
        }),
      });

      const js = await response.json();

      if (js.errcode === 0) {
        const message ="Письмо с инструкциями отравлено на почту";
        setMsg(message);
        setTimeout(() => navigate("/", { replace: true }), 1500);
      } else handleActionForErrorOnce(js.errcode);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form className="login-stile-section" onSubmit={handleResetPassword}>
        <div className="img-logo" />
        <div className="login-stile-form">
          <label className="login-stile-label">Username</label>
          <div className="login-stile-input">
            <CiUser size={22} color="#CCCCCC" />
            <input
              style={{ border: "0", background: "none" }}
              type="text"
              name="username"
              size="26"
              placeholder="#username"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              autoComplete="new-password"
              required
            />
          </div>
        </div>
        <div className="login-stile-form">
          <label className="login-stile-label">Email</label>
          <div className="login-stile-input">
            <CiMail size={22} color="#CCCCCC" />
            <input
              style={{ border: "0", background: "none" }}
              size="26"
              type="email"
              placeholder="Адрес электронной почты"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <button
          className="login-stile-button"
          style={{ width: "295px", marginTop: "-10px" }}
          type="submit"
        >
          Отправить
        </button>
        <a href="/" className="login-stile-a" style={{ marginTop: "20px" }}><RxExit className="m-size" style={{margin:'0 .5em'}}/>
          Вернуться на страницу входа
        </a>

         <p className={error ? 'error-notification' : 'offscreen'} aria-live="assertive" ref={errRef}>{error}</p> 
         <p className={msg ? "msg-notification": 'offscreen'} aria-live="assertive" ref={errRef}>{msg}</p> 
      </form>
    </>
  );
};

export default EmailSend;

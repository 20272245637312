import React from "react";
import "../../../modal-window/modal.css";

export const GoodeModalWindow = ({ closeModal, text }) => {
  const handleCloseModal = () => {
    closeModal();
  };
  return (
    <div className={`   is-active" `}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">Ответ</p>
        </header>
        <section className="modal-card-body">
          <div>{text}</div>
        </section>
        <button
          className="delete"
          aria-label="close"
          onClick={() => handleCloseModal()}
        >
          Ок
        </button>
      </div>
    </div>
  );
};

import { ERROR_CODES } from "../errorCodes";

export const setError = (errorCode, errorData) => ({
  type: "SET_ERROR",
  payload: {
    errorCode,
    errorData,
  },
});

export const clearError = () => ({
  type: "CLEAR_ERROR",
});
export const CLOSE_MODAL = "CLOSE_MODAL";
export const setModalData = (modalData) => ({
  type: "SET_MODAL_DATA",
  payload: modalData,
});
export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const handleActionForError = (errorCode, navigate, dispatch) => {
  switch (errorCode) {
    case ERROR_CODES.USER_NOT_EXIST_ADMIN:
      return {
        title: "Ошибка",
        content: "Пользователя не существует",
      };
    case ERROR_CODES.INVALID_LOGIN_OR_PASSWORD_ADMIN:
      return {
        title: "Ошибка",
        content: "Не верный логин или пароль",
      };
    case ERROR_CODES.MISSING_DATA_RECORDS:
      return {
        title: "Ошибка",
        content: "Данные не найдены",
      };
    case ERROR_CODES.INVALID_DATE_OR_TIME:
      return {
        title: "Ошибка",
        content: "Не верно указана дата или время",
      };
    case ERROR_CODES.INVALID_ROOM:
      return {
        title: "Ошибка",
        content: "Не верно указана комната",
      };
    case ERROR_CODES.INVALID_TOKEN:
      navigate("/error");
      break;
    case ERROR_CODES.NETWORK_OR_INTERNAL_SERVER_ERROR:
      return {
        title: "Ошибка",
        content:
          "Упс! Произошла внутренняя ошибка, перезагрузите страницу если не поможет то обратитесь к администратору сайта",
      };
    default:
      return {
        title: "Ошибка",
        content: "Не удается связаться с сервером",
      };
  }
};
export default handleActionForError;

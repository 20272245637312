import React, { useState, useEffect, useRef } from "react";

import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBinLine, RiEditLine } from "react-icons/ri";
import { AiOutlineDown, AiOutlineUp, AiOutlineClose } from "react-icons/ai";
import { IoIosSearch } from "react-icons/io";
import { FaCheck } from "react-icons/fa";

import {
  handleActionForError,
  setModalData,
} from "../../../redux/actions/errorActions";
import ModalError from "../../modal-window/modal";
import "react-calendar/dist/Calendar.css";
import "./Tabs/tabs.css";
const MY_TOKEN = process.env.REACT_APP_USER_TOKEN;
const MY_API = process.env.REACT_APP_USER_API;

const Users_Info = () => {
  const [loading, setLoading] = useState(false);
  const [laundryValues, setLaundryValues] = useState([]);
  const [hoveredUserId, setHoveredUserId] = useState(null);
  const [userId, setUserId] = useState("");
  const [userByName, setUserByName] = useState("");
  const [editedRooms, setEditedRooms] = useState({});
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [isFilterCollapsed, setIsFilterCollapsed] = useState(false);
  const [filterSelected, setFilterSelected] = useState(false);
  const isInitialMount = useRef(true);
  const errorModal = useSelector((state) => state.error.modalData);
  const filtersUserRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRequestUserInfo = async () => {
    try {
      const response = await fetch(`${MY_API}/get_user_info`, {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${MY_TOKEN}`,
        },
      });

      const result = await response.json();

      if (result.errcode === 0) {
        setLaundryValues(result.result);
      } else {
        const modalData = handleActionForError(
          result.errcode,
          navigate,
          dispatch
        );
        dispatch(setModalData(modalData));
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  const handleUserByName = async () => {
    try {
      const fullName = userByName;

      const [firstName, lastName] = fullName.split(" ");

      const response = await fetch(
        `${MY_API}/get_usr_by_name?first_name=${firstName}&last_name=${lastName}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${MY_TOKEN}`,
          },
        }
      );

      const result = await response.json();

      if (result.errcode === 0) {
        setLaundryValues(result.result);
      } else {
        const modalData = handleActionForError(result.errcode);
        dispatch(setModalData(modalData));
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleUserId = async () => {
    try {
      const response = await fetch(
        `${MY_API}/get_usr_by_id?user_id=${userId}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${MY_TOKEN}`,
          },
        }
      );

      const result = await response.json();

      if (result.errcode === 0) {
        setLaundryValues(result.result);
      } else {
        const modalData = handleActionForError(result.errcode);
        dispatch(setModalData(modalData));
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await handleRequestUserInfo();
      setLoading(false);
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchData();
    }
  }, []);

  const handleBanToggle = async (userId, currentBanStatus) => {
    console.log(`Toggling ban for user ${userId}`);
    let endpoint, action;
    if (currentBanStatus === "0") {
      endpoint = "/ban_user";
      action = "ban";
    } else {
      endpoint = "/unban_user";
      action = "unban";
    }
    try {
      const response = await fetch(`${MY_API}${endpoint}?user_id=${userId}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${MY_TOKEN}`,
        },
      });

      const result = await response.json();

      if (result.errcode === 0) {
        await fetch(`${MY_API}/get_usr_by_id?user_id=${userId}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${MY_TOKEN}`,
          },
        });

        // setTimeout(() => {
        handleRequestUserInfo();
        // }, 2000);
      } else {
        const modalData = handleActionForError(result.errcode);
        dispatch(setModalData(modalData));
      }
    } catch (error) {
      console.error(`Error ${action} user:`, error.message);
    }
  };
  const handleLastDate = async (user_id) => {
    try {
      const response = await fetch(
        `${MY_API}/delete_last_date?user_id=${user_id}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${MY_TOKEN}`,
          },
        }
      );

      const result = await response.json();

      if (result.errcode === 0) {
        await fetch(`${MY_API}/get_usr_by_id?user_id=${user_id}`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${MY_TOKEN}`,
          },
        });
        handleRequestUserInfo();
      } else {
        const modalData = handleActionForError(result.errcode);
        dispatch(setModalData(modalData));
      }
    } catch (error) {
      console.error("Error updating room:", error.message);
    }
  };

  const filterOptions = [
    { value: "1", label: "Id пользователя" },
    { value: "2", label: "Имя Фамилия" },
  ];

  const handleCheckboxChange = (value, label) => {
    setSelectedFilter((prevFilter) => (prevFilter === value ? null : value));
    setSelectedFilters((prevFilters) =>
      prevFilters.includes(value)
        ? prevFilters.filter((filter) => filter !== value)
        : [value]
    );
    if (filterValue !== "") {
      setFilterSelected(false);
    } else {
      setFilterVisible(false);
      setFilterSelected(true);
    }
    if (selectedFilter === value) {
      setFilterValue("");
      setFilterVisible(true);
    } else {
      setFilterValue(label);
      setIsFilterCollapsed(false);
      setSelectedFilter(null);
    }
  };

  const handleClickOutside = (event) => {
    if (filtersUserRef.current && !filtersUserRef.current.contains(event.target)) {
      // Click outside filters-user, so close it
      setFilterVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
 
  const handleResetFilter = () => {
    setSelectedFilter(null);
    setSelectedFilters([]);
    setFilterValue("");
    setIsFilterCollapsed(false);
  };

  const handleToggleFilter = () => {
    setFilterVisible(!filterVisible);
    if (!filterVisible) {
      handleResetFilter();
      setFilterSelected(false);
    } else {
      setFilterSelected(true);
    }
  };

  const handleToggleCollapse = () => {
    setIsFilterCollapsed(!isFilterCollapsed);
  };

  const [room, setRoom] = useState("");
  const [editableRowId, setEditableRowId] = useState(null);

  const handleRoomChange = (event, user) => {
    const { value } = event.target;
    setEditedRooms((prevRooms) => ({
      ...prevRooms,
      [user.user_id]: value,
    }));
    setRoom(value);
  };

  const handleRoomChangeStart = async (user) => {
    console.log("Submitting changes for user:", user.user_id, room);

    const response = await fetch(
      `${MY_API}/new_room?user_id=${user.user_id}&room=${room}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${MY_TOKEN}`,
        },
      }
    );

    const result = await response.json();
    if (result.errcode === 0) {
      await fetch(`${MY_API}/get_usr_by_id?user_id=${user.user_id}`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${MY_TOKEN}`,
        },
      });
      handleRequestUserInfo();
      setEditableRowId(null);
    } else {
      console.log("Submitting changes for user:", result.errcode);
      const modalData = handleActionForError(result.errcode);
      setEditableRowId(null);
      setEditedRooms(user.room);
      dispatch(setModalData(modalData));
    }
  };
  return (
    <>
      <div className="filters-user"ref={filtersUserRef}>
        <div>
          <button
            className="filter-stile-select" 
            onClick={handleToggleFilter}
            style={{ position: "relative" }}
          >
            {filterValue || "Выберите фильтр"}
            {filterVisible && (
              <>
                {!isFilterCollapsed && (
                  <AiOutlineUp
                    style={{ marginLeft: "50px" }}
                    onClick={() => setFilterVisible(false)}
                  />
                )}
              </>
            )}

            {!filterVisible && (
              <>
                {!filterSelected && !isFilterCollapsed ? (
                  <AiOutlineDown
                    style={{ marginLeft: "50px", cursor: "pointer" }}
                    onClick={handleToggleCollapse}
                  />
                ) : (
                  <AiOutlineClose
                    style={{ marginLeft: "50px", cursor: "pointer" }}
                    onClick={() => {
                      setIsFilterCollapsed(true);
                      handleResetFilter();
                      handleRequestUserInfo();
                    }}
                  />
                )}
              </>
            )}
          </button>

          {filterVisible && !isFilterCollapsed && (
            <div className="boxes">
              {filterOptions.map((option) => (
                <div key={option.value}>
                  <input
                    type="checkbox"
                    id={`box-${option.value}`}
                    checked={selectedFilters.includes(option.value)}
                    onChange={() =>
                      handleCheckboxChange(option.value, option.label)
                    }
                    disabled={selectedFilter && selectedFilter !== option.value}
                  />
                  <label
                    htmlFor={`box-${option.value}`}
                    className={
                      selectedFilter !== option.value ? "inactive" : ""
                    }
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>
        {filterValue && (
          <div>
            {filterValue === "Id пользователя" ? (
              <div className="scerch-but">
                <input
                  type="text"
                  className="stile-input"
                  placeholder="Id пользователя"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleUserId();
                    }
                  }}
                />
                <button
                  type="button"
                  className="calend search"
                  onClick={handleUserId}
                >
                  <IoIosSearch size={"20px"} />
                </button>
              </div>
            ) : filterValue === "Имя Фамилия" ? (
              <div className="scerch-but">
                <input
                  type="text"
                  className="stile-input"
                  placeholder="Имя Фамилия"
                  value={userByName}
                  onChange={(e) => setUserByName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      handleUserByName();
                    }
                  }}
                />
                <button
                  type="button"
                  className="calend search"
                  onClick={handleUserByName}
                >
                  <IoIosSearch size={"20px"} />
                </button>
              </div>
            ) : null}
          </div>
        )}
      </div>

      <div
        style={{ overflowY: "auto", maxHeight: "670px", position: "relative" }}
      >
        <table className="journal-table">
          <thead className="journal-thread">
            <tr>
              <th className="th-line th-padding-index">№</th>
              <th className="th-line th-padding">Id пользователя</th>
              <th className="th-line th-padding">Имя</th>
              <th className="th-line th-padding">Фамилия</th>
              <th className="th-line th-padding ">Последняя запись</th>
              <th className="th-line th-padding">Комната</th>
              <th className="th-padding"> Бан \ не бан</th>
            </tr>
          </thead>

          <tbody className="journal-tbody">
            {loading ? (
              <tr>
                <td colSpan={7}>
                  <div
                    className="loading"
                    style={{
                      height: "650px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ReactLoading
                      type={"spin"}
                      color={"#5A7EF2"}
                      height={50}
                      width={50}
                    />
                  </div>
                </td>
              </tr>
            ) : (
              laundryValues?.map((item, index) => (
                <tr
                  key={index}
                  className={
                    index % 2 === 1
                      ? "row-hover even-row"
                      : "row-hover noeven-row"
                  }
                >
                  <td className="th-line th-padding">{index + 1}</td>
                  <td className="th-line th-padding">{item.user_id}</td>
                  <td className="th-line th-padding">{item.first_name}</td>
                  <td className="th-line th-padding">{item.last_name}</td>
                  <td className="th-line th-padding ">
                    <div className="line-center">
                      <p className="line-p">{item.last_date}</p>
                      <RiDeleteBinLine
                        type="button"
                        className="buttonDelete"
                        onClick={() => handleLastDate(item.user_id)}
                      />
                    </div>
                  </td>
                  <td
                    className="th-line th-padding"
                    // onKeyDown={(e) => handleKeyPress(e, item)}
                  >
                    <div className="line-center-room">
                      <input
                        type="text"
                        className="line-p-room"
                        value={editedRooms[item.user_id] || item.room}
                        readOnly={
                          !editableRowId || editableRowId !== item.user_id
                        }
                        onChange={(e) => handleRoomChange(e, item)}
                      />
                      {editableRowId === item.user_id ? (
                        <FaCheck
                          className="buttonCheck"
                          onClick={() => handleRoomChangeStart(item)}
                        />
                      ) : (
                        <RiEditLine
                          className="buttonEdit"
                          onClick={() => setEditableRowId(item.user_id)}
                        />
                      )}
                    </div>
                  </td>
                  <td
                    className="th-padding"
                    onMouseEnter={() => setHoveredUserId(item.user_id)}
                    onMouseLeave={() => setHoveredUserId(null)}
                  >
                    {item.ban === "0" ? (
                      <button
                        className="ban-button not-banned"
                        onClick={() => handleBanToggle(item.user_id, item.ban)}
                      >
                        {hoveredUserId === item.user_id
                          ? "Заблокировать"
                          : "Не заблокирован"}
                      </button>
                    ) : (
                      <button
                        className="ban-button banned"
                        onClick={() => handleBanToggle(item.user_id, item.ban)}
                      >
                        {hoveredUserId === item.user_id
                          ? "Разблокировать"
                          : "Заблокирован"}
                      </button>
                    )}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <ModalError
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, .9)",
          },
        }}
        isOpen={errorModal.isOpen}
        onClose={errorModal.closeModal}
        title={errorModal.title}
        content={errorModal.content}
      />
    </>
  );
};

export default Users_Info;

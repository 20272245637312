import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import { CiUser, CiLock } from "react-icons/ci";
import { connect } from "react-redux";
import { loginFailure } from "../../redux/actions/authActions";
import { handleActionForError } from "../../redux/actions/errorActions";

import "./login.css";

const MY_TOKEN = process.env.REACT_APP_USER_TOKEN;
const MY_API = process.env.REACT_APP_USER_API;

function Login({ dispatch }) {
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [loggedIn, setLoggedIn] = useState(
    sessionStorage.getItem("loggedIn") === "true"
  );
  const [role, setRole] = useState(sessionStorage.getItem("roles"));

  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const handleActionForErrorOnce = (errorCode) => {
    if (!error) {
      const errorMessage = handleActionForError(errorCode);
      setError(errorMessage.content);
    }
  };

  const handleLogin = async () => {
    try {
      const response = await fetch(
        `${MY_API}/check_admin?login=${user}&passw=${pwd}`,
        {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${MY_TOKEN}`,
          },
        }
      );

      const js = await response.json();

      if (js.errcode === 0) {
        handleRoles(user, pwd);
      } else handleActionForErrorOnce(js.errcode);
    } catch (error) {
      handleActionForErrorOnce(error);
    }
  };
  const handleRoles = async (user, pwd) => {
    const response = await fetch(
      `${MY_API}/check_role?login=${user}&passw=${pwd}`,
      {
        method: "POST",
        headers: {
          accept: "application/json",
          Authorization: `Bearer ${MY_TOKEN}`,
        },
      }
    );

    const js = await response.json();
    if (js.errcode === 0) {
      const roles = js.result.roles;
      const bot_access = js.result.bot_access;
      const botAccessList = Object.entries(bot_access).map(([id, value]) => ({
        id: parseInt(id),
        value,
      }));
      const botAccessData = { bot_access: botAccessList };
      sessionStorage.setItem("botAccess", JSON.stringify(botAccessData));
      sessionStorage.setItem("roles", JSON.stringify(roles));
      sessionStorage.setItem("loggedIn", JSON.stringify(true));

      setLoggedIn(loggedIn);
      setRole(role);

      navigate("/");
      window.location.reload();
    } else {
      dispatch(loginFailure());
    }
  };

  return (
    <>
      <section className="login-stile-section">
        <div className="img-logo" />
        {error && <div className="error-notification">{error}</div>}
        <div className="login-stile-form">
          <label className="login-stile-label">Username</label>
          <div className="login-stile-input">
            <CiUser size={22} color="#CCCCCC" />
            <input
              style={{ border: "0", background: "none" }}
              autoComplete="new-password"
              type="text"
              name="username"
              size="26"
              placeholder="#username"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              required
            />
          </div>
        </div>
        <div className="login-stile-form ">
          <label className="login-stile-label">Password</label>
          <div className="login-stile-input">
            <CiLock size={22} color="#CCCCCC" />
            <input
              style={{ border: "0", background: "none" }}
              type="password"
              name="password"
              size="26"
              placeholder="#password"
              autoComplete="new-password"
              onChange={(e) => setPwd(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleLogin();
                }
              }}
              value={pwd}
              required
            />

            <input className="login-stile-input" type="hidden" name="token" />
            <input className="login-stile-input" type="hidden" name="next" />
          </div>
        </div>

        <div>
          <button
            className="login-stile-button"
            onClick={handleLogin}
            style={{ padding: "0.3rem 8rem" }}
          >
            Login
          </button>
          <a href="/resetpassword" className="login-stile-a">
            Забыли пароль?
          </a>
         
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => ({
  loggedIn: state.auth.loggedIn,
  role: state.auth.role,
});

export default connect(mapStateToProps)(Login);

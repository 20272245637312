import React from "react";

export const Confirmation = ({
    notShowModal,
    handleConfirmation,
    closeModal,
  }) => {
    return (
      <>
        <section className="modal-stile-section">
          <div className="img-logo-laundry" />
          <h2 className="confirmation-stile-header-h2">
            Подтвердите удаление записи на стирку
          </h2>
  
          <div className="confirmation-check-buttons">
            <button
              className="confirmation-stile-button"
              onClick={handleConfirmation}
              style={{ background: "#479914" }}
            >
              Подтвердить
            </button>
            <button
              className="modal-stile-button"
              style={{ background: "#991414" }}
              onClick={() => {
                notShowModal();
                closeModal();
              }}
            >
              Отмена
            </button>
          </div>
        </section>
      </>
    );
  };
  
import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactLoading from "react-loading";
import Calendar from "react-calendar";
import { format } from "date-fns";
import moment from "moment";
import Modal from "react-modal";

import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { IoIosSearch } from "react-icons/io";

import { DeleteEntry } from "./ModalWindows/delete-entry";

import {
  handleActionForError,
  setModalData,
} from "../../../redux/actions/errorActions";
import ModalError from "../../modal-window/modal";

import "react-calendar/dist/Calendar.css";
import "./Tabs/tabs.css";
import "./laundry.css";

const MY_TOKEN = process.env.REACT_APP_USER_TOKEN;
const MY_API = process.env.REACT_APP_USER_API;

const Laundry_Table = () => {
  const [room, setRoom] = useState("");
  const [laundryValues, setLaundryValues] = useState([]);
  const [calendarVisible, setCalendarVisible] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const errorModal = useSelector((state) => state.error.modalData);
  const filtersUserRef = useRef(null);
  const enterPressed = useRef(false);
  const isInitialMount = useRef(true);
  const calendarContainerRef = useRef(null);
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isModalOpen) {
        const message =
          "You have unsaved changes. Are you sure you want to leave?";
        e.returnValue = message; // Standard for most browsers
        return message; // For some older browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isModalOpen]);
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        calendarContainerRef.current &&
        !calendarContainerRef.current.contains(e.target)
      ) {
        setCalendarVisible(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const formatDate = (date) => format(date, "dd.MM.yyyy");

  const handleCalendarChange = (value) => {
    if (value.length === 2) {
      setStartDate(value[0]);
      setEndDate(value[1]);
    }
  };

  useEffect(() => {
    setLoading(true);
    console.log("useEffect:", isInitialMount);
    const defaultDateFrom = moment()
      .subtract(7, "day")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    const defaultDateTo = moment().set({
      hour: 23,
      minute: 59,
      second: 59,
      millisecond: 999,
    });

    setStartDate(defaultDateFrom.toDate());
    setEndDate(defaultDateTo.toDate());

    const fetchData = async () => {
      try {
        if (startDate && endDate) {
          const response = await fetch(
            `${MY_API}/laundry_values?start_date=${formatDate(
              startDate
            )}&end_date=${formatDate(endDate)}`,
            {
              method: "POST",
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${MY_TOKEN}`,
              },
            }
          );

          const result = await response.json();

          if (result.errcode === 0) {
            const sortedData = result.result.sort((a, b) => {
              const dateA = new Date(`${a.date} ${a.time}`);
              const dateB = new Date(`${b.date} ${b.time}`);
              return dateA - dateB;
            });

            setLaundryValues(sortedData);
            setIsDataFetched(true);
          } else {
            const modalData = handleActionForError(
              result.errcode,
              navigate,
              dispatch
            );
            dispatch(setModalData(modalData));
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    if (!isInitialMount.current) {
      fetchData();
    } else {
      isInitialMount.current = false;
    }
  }, []);

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      try {
        if (startDate && endDate) {
          const response = await fetch(
            `${MY_API}/laundry_values?start_date=${formatDate(
              startDate
            )}&end_date=${formatDate(endDate)}`,
            {
              method: "POST",
              headers: {
                accept: "application/json",
                Authorization: `Bearer ${MY_TOKEN}`,
              },
            }
          );

          const result = await response.json();

          if (result.errcode === 0) {
            const sortedData = result.result.sort((a, b) => {
              const dateA = new Date(`${a.date} ${a.time}`);
              const dateB = new Date(`${b.date} ${b.time}`);
              return dateA - dateB;
            });

            setLaundryValues(sortedData);
            setIsDataFetched(true);
          } else {
            const modalData = handleActionForError(
              result.errcode,
              navigate,
              dispatch
            );
            dispatch(setModalData(modalData));
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      fetchData();
    }
  }, [startDate, endDate]);

  const handleRequestRoom = async () => {
    enterPressed.current = true;
    if (room.trim() !== "") {
      try {
        const response = await fetch(`${MY_API}/laundry_room?room=${room}`, {
          method: "POST",
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${MY_TOKEN}`,
          },
        });

        const result = await response.json();

        if (result.errcode === 0) {
          const sortedData = result.result.sort((a, b) => {
            const dateA = new Date(`${a.date} ${a.time}`);
            const dateB = new Date(`${b.date} ${b.time}`);
            return dateA - dateB;
          });

          setLaundryValues(sortedData);
          setIsDataFetched(true);
        } else {
          const modalData = handleActionForError(
            result.errcode,
            navigate,
            dispatch
          );
          dispatch(setModalData(modalData));
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    }
  };
  const validateInput = (inputValue) => {
    // Регулярное выражение для поиска трёх цифр подряд с возможной последующей буквой
    const regex = /^\d{3}[а]?$/;
    return regex.test(inputValue);
  };
  const handleSearchRoom = (e) => {
    const value = e;
    console.log(value);
    if (value.length >= 1 && isNaN(value[0])) {
      // Проверяем, что первый символ не является цифрой
      setRoom(""); // Очищаем значение, если первый символ не цифра
    } else if (validateInput(value)) {
      setRoom(value);
    } else setRoom(value);
  };

  const handleClickOutside = (event) => {
    if (filtersUserRef.current && !filtersUserRef.current.contains(event.target)) {
      // Click outside filters-user, so close it
      setCalendarVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <>
      <div className="filters">
        <div>
          <input
            type="text"
            className="stile-input"
            placeholder="Комната"
            value={room}
            onChange={(e) => handleSearchRoom(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleRequestRoom();
              }
            }}
          />
          {room.length >= 3 && room.length <= 4 && !validateInput(room) && (
            <p style={{ color: "red" }}>Не верно указан номер комнаты</p>
          )}
          <button
            type="button"
            className="calend search"
            onClick={handleRequestRoom}
          >
            <IoIosSearch size={"20px"} />
          </button>
        </div>

        <div className="calendar-container" ref={filtersUserRef}>
          <button
            className="calend"
            onClick={() => setCalendarVisible(!calendarVisible)}
          >
            <input
              readOnly
              type="text"
              className="stile-html cursor-data"
              onFocus={() => setCalendarVisible(calendarVisible)}
              placeholder="Выберите дату"
            />
            {calendarVisible ? (
              <AiOutlineUp style={{ marginLeft: "-5px" }} />
            ) : (
              <AiOutlineDown style={{ marginLeft: "-5px" }} />
            )}
          </button>
          {calendarVisible && (
            <Calendar
              onChange={handleCalendarChange}
              selectRange={true}
              className="react-calendar"
            />
          )}
        </div>

        <div>
          <button type="butDelete" className="butDelete" onClick={openModal}>
            Удалить запись
          </button>
        </div>

        <Modal
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, .7)",
            },
          }}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className="admin-modal"
        >
          <DeleteEntry closeModal={closeModal} />
        </Modal>
      </div>
      <div
        style={{ overflowY: "auto", maxHeight: "670px", position: "relative" }}
      >
        <table className="journal-table">
          <thead className="journal-thread">
            <tr>
              <th className="th-line th-padding-index">№</th>
              <th className="th-line th-padding">Дата</th>
              <th className="th-line th-padding">Время</th>
              <th className="th-line th-padding">Машинка №1</th>
              <th className="th-padding">Машинка №2</th>
            </tr>
          </thead>

          <tbody className="journal-tbody">
            {loading ? (
              <tr>
                <td colSpan={7}>
                  <div
                    className="loading"
                    style={{
                      height: "650px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ReactLoading
                      type={"spin"}
                      color={"#5A7EF2"}
                      height={50}
                      width={50}
                    />
                  </div>
                </td>
              </tr>
            ) : (
              laundryValues.map((item, index) => (
                <tr
                  key={index}
                  className={
                    index % 2 === 1
                      ? "row-hover even-row"
                      : "row-hover noeven-row"
                  }
                >
                  <td className="th-line th-padding">{index + 1}</td>
                  <td className="th-line th-padding">{item.date}</td>
                  <td className="th-line th-padding">{item.time}</td>
                  <td className="th-line th-padding">{item.slot_1}</td>
                  <td className="th-padding">{item.slot_2}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <ModalError
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, .9)",
          },
        }}
        isOpen={errorModal.isOpen}
        onClose={errorModal.closeModal}
        title={errorModal.title}
        content={errorModal.content}
      />
    </>
  );
};

export default Laundry_Table;

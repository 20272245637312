import React from "react";
import { useNavigate } from 'react-router-dom';
import { MdChevronLeft } from "react-icons/md";

import Tabs from "./Tabs/Tabs"
import laundryImage from '../../../assets/image/laundry.png';
import "./laundry.css";

const Laundry = () => {
    const navigate = useNavigate();
    function ToBots() {
        navigate({
            pathname: '/ '
        });
    }
    return (
        <>
            <div style={{ marginLeft: '10%' }} >

                <div className="page-header">
                    <img src={laundryImage} alt="" className="card-icon" width='60px' height='60px' />
                    <h2 className="header-h2 ">Laundry | Запись на стирку РФФ</h2>
                    <button className="buttonLeft" onClick={ToBots}>
                        <MdChevronLeft  className="mob-size" />
                    </button>
                </div>
                <Tabs />
            </div>
        </>

    );
};

export default Laundry;
import React, { useState } from "react";

import { handleActionForError } from "../../../redux/actions/errorActions";
import "../../login/login.css";
import "./admin.css";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";

const MY_TOKEN = process.env.REACT_APP_USER_TOKEN;
const MY_API = process.env.REACT_APP_USER_API;

const Admin = ({ closeModal }) => {
  const [user, setUser] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [roles, setRoles] = useState("");
  const [botAccess, setBotAccess] = useState("");
  const [isRequesting, setIsRequesting] = useState(false);
  const [error, setError] = useState("");
  const [filterVisible, setFilterVisible] = useState(false);
  const [isFilterCollapsed, setIsFilterCollapsed] = useState(false);

  const handleActionForErrorOnce = (errorCode) => {
    if (!error) {
      const errorMessage = handleActionForError(errorCode);
      setError(errorMessage.content);
    }
  };

  const botsList = [{ value: "laundry_vk", label: "VK Laundry" }];

  const handleAdmin = async () => {
    if (isRequesting) {
      return;
    }
    setIsRequesting(true);

    try {
      const response = await fetch(`${MY_API}/create_admin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${MY_TOKEN}`,
        },
        body: JSON.stringify({
          login: user,
          name: name,
          last_name: lastName,
          roles: roles,
          bot_access: [botAccess],
        }),
      });

      const js = await response.json();

      if (js.errcode !== 0) {
        handleActionForErrorOnce(js.errcode);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsRequesting(false);
    }
  };

  const handleToggleFilter = () => {
    setFilterVisible((prevVisible) => !prevVisible);
  };

  const handleToggleCheckbox = (value) => {
    setBotAccess((prevAccess) => {
      if (prevAccess.includes(value)) {
        return prevAccess.filter((item) => item !== value);
      } else {
        return [...prevAccess, value];
      }
    });
    setFilterVisible(false);
  };
  return (
    <>
      <section className="admin-stile-section">
        <div className="img-logo-admin" />
        <h2 className="admin-stile-header-h2">Добавление админа</h2>
        <p className={"errors"}>{error}</p>
        <label className="admin-stile-label">Логин:</label>
        <input
          style={{ border: "0", background: "none" }}
          className="admin-stile-input"
          type="text"
          name="username"
          size="26"
          placeholder="Username"
          onChange={(e) => setUser(e.target.value)}
          value={user}
          required
        />

        <label className="admin-stile-label">Имя:</label>
        <input
          style={{ border: "0", background: "none" }}
          className="admin-stile-input"
          type="text"
          name="username"
          size="26"
          placeholder="Name admin"
          onChange={(e) => setName(e.target.value)}
          value={name}
          autoComplete="username"
          required
        />

        <label className="admin-stile-label">Фамилия:</label>
        <input
          style={{ border: "0", background: "none" }}
          className="admin-stile-input"
          type="text"
          size="26"
          placeholder="Last name"
          onChange={(e) => setLastName(e.target.value)}
          value={lastName}
          required
        />

        <label className="admin-stile-label">Роль:</label>
        <select
          className="admin-stile-select"
          onChange={(e) => setRoles(e.target.value)}
          value={roles}
          required
        >
          <option value="">Выберите роль</option>
          <option value="God">Главный администратор</option>
          <option value="Admin">Администратор</option>
          {/* Add more roles as needed */}
        </select>

        <label className="admin-stile-label">Бот:</label>
        <div>
          <button
            className="admin-stile-select-s"
            onClick={handleToggleFilter}
            style={{ position: "relative" }}
          >
            {botAccess.length !== 0 ? [botAccess] : "Назначьте список ботов"}
            {filterVisible ? (
              <BiChevronUp
                size={20}
                style={{ marginLeft: "60px", cursor: "pointer" }}
                onChange={() => setIsFilterCollapsed(false)}
              />
            ) : (
              <BiChevronDown
                size={20}
                style={{ cursor: "pointer" }}
                onChange={() => setIsFilterCollapsed(true)}
              />
            )}
          </button>

          {filterVisible && !isFilterCollapsed && (
            <div className="admin-boxes">
              {botsList.map((option) => (
                <div key={option.value}>
                  <input
                    type="checkbox"
                    id={`checkbox-${option.value}`}
                    checked={botAccess.includes(option.value)}
                    onChange={() => handleToggleCheckbox(option.value)}
                  />
                  <label
                    htmlFor={`checkbox-${option.value}`}
                    className={
                      botAccess.includes(option.value) ? "" : "inactive"
                    }
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="check-buttons">
          <button
            className="admin-stile-button"
            style={{ background: "#479914" }}
            onClick={() => handleAdmin() && closeModal()}
          >
            Создать
          </button>
          <button
            className="admin-stile-button"
            style={{ background: "#991414" }}
            onClick={closeModal}
          >
            Отменить
          </button>
        </div>
      </section>
    </>
  );
};

export default Admin;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Login from "./pages/login/login";
import EmailPage from "./pages/login/email-send";
import Bots from "./pages/bots/bots";
import Admin from "./pages/bots/create-admin/admin";
import Laundry from "./pages/bots/laundry/laundry";
import Error from "./pages/error-page/error-page";

import { ThemeProvider } from "styled-components";
import { GlobalStyles, firstTheme } from "./assets/theme/globalStyle";

const loggedIn = sessionStorage.getItem("loggedIn");
const role = sessionStorage.getItem("roles");
function checkAccess(roles, loggedIn, role) {
  const allowedRoles = ["Admin", "God"];
  const result =
    loggedIn === "true" && roles.some((r) => allowedRoles.includes(r));

  return result;
}

const App = () => {
  return (
    <ThemeProvider theme={firstTheme}>
      <Router>
        <GlobalStyles />
        <Routes>
          <Route path="/resetpassword" element={<EmailPage />} />
          <Route path="/Error" element={<Error />} />
          {loggedIn ? (
            <>
              <Route
                path="/*"
                element={
                  <>
                    <Routes>
                      {checkAccess(["Admin", "God"], loggedIn, role) ? (
                        <>
                          <Route path="/" element={<Bots />} />
                          <Route path="/laundry" element={<Laundry />} />
                        </>
                      ) : (
                        <Route path="/Error" element={<Error />} />
                      )}

                      {checkAccess(["God"], loggedIn, role) ? (
                        <>
                          <Route path="/createAdmin" element={<Admin />} />
                        </>
                      ) : (
                        <Route path="/Error" element={<Error />} />
                      )}
                    </Routes>
                  </>
                }
              />
            </>
          ) : (
            <Route exact path="/" element={<Login />} />
          )}
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;

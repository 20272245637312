import { combineReducers } from "redux";
import authReducer from './redusers/authReducer';

import errorReducer from './redusers/errorReducer';


const appReducer = combineReducers({

    auth: authReducer,
    error: errorReducer
});

const rootReducer = (state, action) => {


    return appReducer(state, action);
};

export default rootReducer;
import React from "react";
import "./modal.css";
import { useDispatch } from "react-redux";

import {
  closeModal
} from "../../redux/actions/errorActions";

const Modals = ({ isOpen, onClose, title, content }) => {
  const dispatch = useDispatch();
  const handleCloseModal = () => {
    dispatch(closeModal());
  };
  
  return (
    <div className={`modal ${isOpen ? "is-active" : ""}`}>
     <div className="modal-background"  ></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
        </header>
        <section className="modal-card-body">
          <div>{content}</div>
        </section>
        <button className="delete" aria-label="close" onClick={()=>handleCloseModal()}>
          Ок
        </button>
      </div>
    </div>
  );
};

export default Modals;

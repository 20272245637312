import React from 'react';

import 'react-calendar/dist/Calendar.css';
import "./Tabs/tabs.css";


const Mailing = () => {
  return (
    <div className="table-laundry">
      <h3 style={{ color: 'white', display: "flex", justifyContent: "center" }}>В разработке</h3>
    </div>
  )
}

export default Mailing
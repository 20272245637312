import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { RxExit } from "react-icons/rx";

import Admin from "./create-admin/admin";
import laundryImage from "../../assets/image/laundry.png";
import sopImage from "../../assets/image/sop.png";
import "./bots.css";

const Card = ({
  id,
  title,
  description,
  buttonColor,
  color,
  buttonText,
  path,
  imageSrc,
}) => {
  return (
    <article className="card" id={id}>
      <div className="card-head">
        <img
          src={imageSrc}
          alt="logo"
          className="card-icon"
          width="60px"
          height="60px"
        />
        <h2 className="card-h2">{title}</h2>
      </div>

      <p className="card-p ">{description}</p>
      <button
        className="tag"
        style={{ background: buttonColor, color: color }}
        onClick={path}
      >
        {buttonText}
      </button>
      <span className="top"></span>
      <span className="right"></span>
      <span className="bottom"></span>
      <span className="left"></span>
    </article>
  );
};

const Bots = () => {
  const [showAdmin, setShowAdmin] = useState(false);
  const [showGod, setShowGod] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const botAccess = JSON.parse(sessionStorage.getItem("botAccess"));

  useEffect(() => {
    Modal.setAppElement("#root");
    const role = sessionStorage.getItem("roles");

    console.log("Role:", role);

    const cleanedRole = role ? role.replace(/"/g, "") : "";

    if (cleanedRole === "God") {
      setShowGod(true);
      setShowAdmin(false);
    } else if (cleanedRole === "Admin") {
      setShowGod(false);
      setShowAdmin(true);
    } else {
      setShowGod(true);
      setShowAdmin(false);
    }
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function ToLaundry() {
    console.log("Navigating to /laundry");
    navigate({
      pathname: "/laundry",
    });
  }

  function ToLogin() {
    sessionStorage.clear();
    window.location.reload();
    navigate({
      pathname: "/ ",
    });
  }

  return (
    <div className="bot-card">
      {(showGod || showAdmin) && (
        <>
          <div className="container-2">
            {botAccess &&
              botAccess.bot_access &&
              botAccess.bot_access.length > 0 && (
                <>
                  {(showGod ||
                    botAccess.bot_access.some(
                      (item) => item.value === "laundry_vk"
                    )) && (
                    <Card
                      id="firs"
                      imageSrc={laundryImage}
                      title="Laundry | Запись на стирку РФФ"
                      description="Я являюсь ботом для записи на стирку. Для начала работы нажмите кнопку 'Начать', если кнопки нет, то просто напишите в этом чате 'Начать'."
                      buttonColor="#001767"
                      color="#CCC"
                      buttonText="Let's go"
                      path={ToLaundry}
                    />
                  )}
                </>
              )}
          </div>

          {showGod && (
            <div className="create-admin">
              <button className="link" onClick={openModal}>
                Добавить Администратора
              </button>
            </div>
          )}
        </>
      )}
      {!showGod && !showAdmin && (
        <div>
          <p> Default content or an error message </p>
        </div>
      )}
      <Modal
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, .7)",
          },
        }}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        className="admin-modal"
      >
        <Admin closeModal={closeModal} />
      </Modal>
      <button className="buttonLogout" onClick={ToLogin}>
        Выйти <RxExit className="m-size" />
      </button>
    </div>
  );
};

export default Bots;

import { createGlobalStyle } from "styled-components";


export const firstTheme = {
  borderTops: ".1rem solid #001767",
  borderRights: ".1rem solid #102366",
  borderBottoms: ".1rem solid #0c1a4d",
  borderLefts: ".1rem solid #160866",
  gradientTop: "linear-gradient(90deg, transparent 20%,rgba(39, 83, 226, 0.5), rgb(57, 60, 206))",
  gradientRight: "linear-gradient(180deg, transparent 30%, rgba(17, 204, 204, 0.5), rgb(0, 255, 255))",
  gradientBottom: "linear-gradient(90deg, rgb(51, 11, 196), rgba(122, 20, 255, 0.5), transparent 50%)",
  gradientLeft: "linear-gradient(180deg, 180deg, rgb(27, 126, 218), rgba(105, 18, 185, 0.5), transparent 70%)",
  buttons: "#CCC"
};

export const GlobalStyles = createGlobalStyle`
body {
  background: #181818
}

.card {
  border-top:  ${({ themeMode }) => themeMode && themeMode.borderTops};
  border-right: ${({ themeMode }) => themeMode && themeMode.borderRights};
  border-bottom: ${({ themeMode }) => themeMode && themeMode.borderBottoms};
  border-left:  ${({ themeMode }) => themeMode && themeMode.borderLefts};
}
.top {
  background: ${({ themeMode }) => themeMode && themeMode.gradientTop};
}

.bottom {
  background: ${({ themeMode }) => themeMode && themeMode.gradientBottom};
}

.right {
  background: ${({ themeMode }) => themeMode && themeMode.gradientRight};
}

.left {
  background: ${({ themeMode }) => themeMode && themeMode.gradientLeft};
}


.journal-table {
  min-width: 100%;
  line-height: 25px;
  text-align: center;
  font-size: 13px;
  font-weight: 350;
  border: none;
  box-shadow: 0px 8px 8px 2px rgba(0, 0, 0, 0.25);  
}
  
.journal-thread {
  height: 40px;
  background: #212121;
  color: #CCC;
  position: sticky;
  top: 0;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.8px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
  
.even-row {
  background-color: #212121;
  color: #ccc;
  height: 30px;
}
  
.noeven-row {
  color: #ccc;
  height: 30px;
}
  
 
.th-line {
  border-right: 2px solid #282828
}
  
.journal-tbody {
  font-size: normal;
  margin-top: 30px;
  box-shadow: 1px 2px 1px rgba(14, 13, 13, 0.5);
  text-align: center;
  width: 100%;
  color: #CCC;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.7px;
}
  
.react-calendar {
  width: 300px;
  max-width: 100%;
  background-color: #282828;
  border: none;
  color: #ccc;
  border-radius: 3px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
  
.react-calendar__navigation button {
  color: #ccc;
  min-width: 34px;
  background: none;
  font-size: 13px;
  margin-top: 8px;
  
}
  
.react-calendar__navigation__label__labelText,
.react-calendar__navigation__label__labelText--from {
  margin: -5px -44px 0px;
}
  
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #282828;  
}
  
.react-calendar__navigation button[disabled] {
  background-color: #0d32bb;
}
  
abbr[title] {
  text-decoration: none;
  color: #395ee0
}
  
.react-calendar__month-view__days__day--weekend {
  color: #d1000098;
}
  
  /*выбор числа - от*/
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #0632cf;
  color: #ccc;
  border-radius: 3px;
}
  
  /* сегодня*/
.react-calendar__tile--now {
  background: #1C1B1B;
  border-radius: 3px;
  font-weight: bold;
  color: #ccc;
}
  
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #001767;
  border-radius: 3px;
  font-weight: bold;
  color: #ccc;
}
  
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #0d33bb;
}
  
.react-calendar__tile--active {
  background: #0d33bb;
  border-radius: 3px;
  font-weight: bold;
  color: #ccc;
}
  
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #0d33bb;
  color: #ccc;
}
  
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #0d33bb8e;
}
  
.react-calendar__tile--range {
  background: #0d33bb8e;
  color: #ccc;
  border-radius: 0;
}
 
.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: #0d33bb;
  color: white;
}
  
.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background: #0d32bb;
  color: #ccc;
}
  
.react-calendar__tile,
.react-calendar__month-view__days__day {
  color: #ccc
}
  
::-webkit-scrollbar {
  width: 6px;
}
  
::-webkit-scrollbar-track {
  -webkit-box-shadow: 5px 5px 5px 5px #1C1B1B inset;
  background-color: rgba(51, 51, 54, 0.144);
  border-radius: 2px;
}
  
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: linear-gradient(180deg, #282828, #282828);
}
  
  `;

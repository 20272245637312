import React from "react";
import "./error-page.css";

const Error = () => {
    return (
        <>
            <div className="error">
                <div className="error__content">
                    <div className="img-logo-error" />
                    <p className="message__text">Sorry, we're not able to find what you're looking for. </p>
                    <div className="error__message">
                        <h1 className="message__title">404</h1>
                    </div>

                    <div className="button_error">
                        <a href="/" className="a e-nav__link">Move to main page</a>
                    </div>
                </div>
                <div className="lamp" />
            </div>
        </>
    );
};

export default Error;
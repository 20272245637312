import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import { format } from "date-fns";
import Modal from "react-modal";

import { BiChevronDown, BiChevronUp } from "react-icons/bi";

import {Confirmation} from './confirmation'
import {
  handleActionForError,
  setModalData,
} from "../../../../redux/actions/errorActions";
import ModalError from "../../../modal-window/modal";

const MY_TOKEN = process.env.REACT_APP_USER_TOKEN;
const MY_API = process.env.REACT_APP_USER_API;

export const DeleteEntry = ({ closeModal }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState("");
    const [selectedMachine, setSelectedMachine] = useState("");
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [calendarVisible, setCalendarVisible] = useState(false);
  
    const errorModal = useSelector((state) => state.error.modalData);
  
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    const timeOptions = [
      { value: "", label: "Не выбрано" },
      { value: "9:00-11:00", label: "9:00-11:00" },
      { value: "12:00-14:00", label: "12:00-14:00" },
      { value: "15:00-17:00", label: "15:00-17:00" },
      { value: "18:00-20:00", label: "18:00-20:00" },
      { value: "21:00-23:00", label: "21:00-23:00" },
    ];
  
    const machineOptions = [
      { value: "", label: "Не выбрано" },
      { value: "1", label: "1-я машинка от окна" },
      { value: "2", label: "2-я машинка от окна" },
    ];
  
    const formatDate = (date) => format(date, "dd.MM.yyyy");
  
    const handleCalendarChange = (date) => setSelectedDate(date);
  
    const showConfirmationModal = () => setIsConfirmationOpen(true);
  
    const closeConfirmationModal = () => setIsConfirmationOpen(false);
  
    const openModal = () => setIsModalOpen(true);
  
    const handleDeleteConfirmation = () => {
      showConfirmationModal();
      openModal();
    };
  
    const handleConfirmation = async () => {
      try {
        if (!selectedDate) {
          console.error("Selected date is null");
          return;
        }
  
        const requestBody = {
          date: formatDate(selectedDate),
          time: selectedTime,
          slot_number: selectedMachine,
        };
  
        const response = await fetch(`${MY_API}/delete_resv`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${MY_TOKEN}`,
          },
          body: JSON.stringify(requestBody),
        });
  
        const result = await response.json();
  
        if (result.errcode === 0) {
          console.log("Deletion successful:", result.result);
  
          closeModal();
        } else {
          const modalData = handleActionForError(
            result.errcode,
            navigate,
            dispatch
          );
          dispatch(setModalData(modalData));
        }
      } catch (error) {
        console.error("Error during deletion:", error);
      } finally {
        closeConfirmationModal();
      }
    };
    const calendarContainerRef = useRef(null);
  
    useEffect(() => {
      const handleOutsideClick = (e) => {
        if (
          calendarContainerRef.current &&
          !calendarContainerRef.current.contains(e.target)
        ) {
          setCalendarVisible(false);
        }
      };
      document.addEventListener("click", handleOutsideClick);
  
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }, []);
    return (
      <>
        <section className="modal-stile-section">
          <div className="img-logo-laundry" />
          <h2 className="modal-stile-header-h2">Удаление записи на стирку</h2>
  
          <label className="modal-stile-label">Выберите дату:</label>
          <div className="calendar-container" ref={calendarContainerRef}>
            <button
              className="modal-stile-select"
              onClick={() => setCalendarVisible(!calendarVisible)}
              style={{ position: "relative" }}
            >
              <input
                type="text"
                className="stile-html"
                onFocus={() => setCalendarVisible(true)}
                placeholder="Выберите дату"
                value={selectedDate ? formatDate(selectedDate) : ""}
                onChange={() => {}}
              />
              {calendarVisible ? (
                <BiChevronUp size={20} style={{ marginRight: "-25px" }} />
              ) : (
                <BiChevronDown size={20} style={{ marginRight: "-25px" }} />
              )}
            </button>
            {calendarVisible && (
              <Calendar
                onChange={(value) => {
                  handleCalendarChange(value);
                  setCalendarVisible(false);
                }}
                selectRange={false}
                className="single-date-calendar react-calendar"
              />
            )}
          </div>
  
          <label className="modal-stile-label">Выберите время:</label>
          <select
            className="modal-stile-select"
            onChange={(e) => setSelectedTime(e.target.value)}
            value={selectedTime}
            required
          >
            {timeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
  
          <label className="modal-stile-label">Выберите машинку:</label>
          <select
            className="modal-stile-select"
            onChange={(e) => setSelectedMachine(e.target.value)}
            value={selectedMachine}
            required
          >
            {machineOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
  
          <div className="check-buttons">
            <button
              className="modal-stile-button"
              onClick={handleDeleteConfirmation}
              style={{ background: "#479914" }}
            >
              Удалить
            </button>
            <button
              className="modal-stile-button"
              style={{ background: "#991414" }}
              onClick={closeModal}
            >
              Отмена
            </button>
  
            <Modal
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, .7)",
                },
              }}
              isOpen={isModalOpen}
              onRequestClose={closeModal}
              className="admin-modal"
            >
              <Confirmation
                notShowModal={closeConfirmationModal}
                handleConfirmation={handleConfirmation}
                closeModal={closeModal} // Передаем функцию closeModal
                isOpen={isConfirmationOpen}
              />
            </Modal>
          </div>
        </section>
        <ModalError
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, .9)",
            },
          }}
          isOpen={errorModal.isOpen}
          onClose={errorModal.closeModal}
          title={errorModal.title}
          content={errorModal.content}
        />
      </>
    );
  };
  
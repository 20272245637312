import React, { useState, useEffect } from "react";

import Laundry_Table from "../laundry-table";
import Users_Info from "../users_info";
import Position from "../position";
import Mailing from "../mailing";

import "react-calendar/dist/Calendar.css";
import "./tabs.css";

const Tab = () => {
  const role = sessionStorage.getItem("roles");

  // const cleanedRole = role ? role.replace(/"/g, "") : "";
  const [showAdmins, setShowAdmins] = useState(false);
  // if (cleanedRole === "Admin") {
  //   setShowAdmins(false);
  // } else {
  //   setShowAdmins(true);
  // }
  const savedActiveTab = localStorage.getItem("activeTab");
  const [activeTab, setActiveTab] = useState(
    savedActiveTab ? parseInt(savedActiveTab) : 1
  );

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab.toString());
  }, [activeTab]);

  const handleTabClick = (id) => {
    console.log("Tab clicked:", id);
    setActiveTab(id);
  };

  return (
    <div>
      <ul className="tabs">
        <li
          className={activeTab === 1 ? "active" : ""}
          onClick={() => handleTabClick(1)}
        >
          СТИРКА
        </li>

        <li
          className={activeTab === 2 ? "active" : ""}
          onClick={() => handleTabClick(2)}
        >
          ПОЛЬЗОВАТЕЛИ
        </li>

        <li
          className={activeTab === 3 ? "active" : ""}
          onClick={() => handleTabClick(3)}
        >
          ПОЗИЦИЯ
        </li>
        {/* {cleanedRole === "God" && ( */}
        <li
          className={activeTab === 4 ? "active" : ""}
          onClick={() => handleTabClick(4)}
        >
          РАССЫЛКА
        </li>
        {/* )} */}
      </ul>

      <div className="tab_content">
        {activeTab === 1 && (
          <div className="tab_panel">
            <Laundry_Table />
          </div>
        )}

        {activeTab === 2 && (
          <div className="tab_panel">
            <Users_Info />
          </div>
        )}

        {activeTab === 3 && (
          <div className="tab_panel">
            <Position />
          </div>
        )}
        {/*  cleanedRole === "God" && */}
        {activeTab === 4 && (
          <div className="tab_panel">
            <Mailing />
          </div>
        )}
      </div>
    </div>
  );
};

export default Tab;

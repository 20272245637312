import React, { useState } from "react";
import { GoodeModalWindow } from "./good-modal";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import ModalError from "../../../modal-window/modal";
import { handleActionForError } from "../../../../redux/actions/errorActions";

const MY_TOKEN = process.env.REACT_APP_USER_TOKEN;
const MY_API = process.env.REACT_APP_USER_API;

export const ChangePosition = ({ closeModal }) => {
  const [userId, setUserId] = useState("");
  const [position, setPosition] = useState("");
  const [text, setText] = useState("");
  const [error, setError] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const errorModal = useSelector((state) => state.error.modalData);

  const closeModalWindow = () => {
    setIsModalOpen(false);
    closeModal();
  };
  const handleActionForErrorOnce = (errorCode) => {
    if (!error) {
      const errorMessage = handleActionForError(errorCode);
      setError(errorMessage);
    }
  };
  const changePosition = async () => {
    try {
      const response = await fetch(
        `${MY_API}/update_position?user_id=${userId}&new_position=${position}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${MY_TOKEN}`,
          },
        }
      );

      const js = await response.json();

      if (js.errcode === 0) {
        setText("Позиция пользователя изменена!");
        setIsModalOpen(true);
      } else {
        handleActionForErrorOnce(js.errcode);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {isModalOpen !== false ? (
        <Modal
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, .7)",
            },
          }}
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
          className="admin-modal"
        >
          <GoodeModalWindow closeModal={closeModalWindow} text={text} />
        </Modal>
      ) : (
        <section className="modal-stile-section">
          {error && <div className="error-notification">{error}</div>}
          <div className="img-logo-laundry" />
          <h2 className="modal-stile-header-h2">
            Изменение машинного состояние пользователя
          </h2>

          <input
            style={{ border: "0", background: "none" }}
            className="modal-stile-input"
            type="text"
            name="username"
            size="26"
            placeholder="ID user"
            onChange={(e) => setUserId(e.target.value)}
            value={userId}
            required
          />

          <input
            style={{ border: "0", background: "none" }}
            className="modal-stile-input"
            type="text"
            name="username"
            size="26"
            placeholder="Новое состояние"
            onChange={(e) => setPosition(e.target.value)}
            value={position}
            autoComplete="username"
            required
          />

          <div className="check-buttons">
            <button
              className="modal-stile-button"
              style={{ background: "#479914" }}
              onClick={changePosition}
            >
              Изменить
            </button>
            <button
              className="modal-stile-button"
              style={{ background: "#991414" }}
              onClick={closeModal}
            >
              Отменить
            </button>
          </div>
          <ModalError
            style={{
              overlay: {
                backgroundColor: "rgba(0, 0, 0, .9)",
              },
            }}
            isOpen={errorModal.isOpen}
            onClose={errorModal.closeModal}
            title={errorModal.title}
            content={errorModal.content}
          />
        </section>
      )}
    </>
  );
};
